import React from "react";
import { Link } from "react-router-dom";

function Footer() {
    return (
        <footer className="footer bg-[#ddd]">
            <div className="footer-container flex flex-col items-center">
                {/*
                <div className="w-1/2 lg:w-1/4">
                    {// Navigation }
                    <ul className="flex justify-evenly mb-3">
                        <li><Link to="/">Home</Link></li>
                        <li><Link to="/about">About</Link></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </div>
                */}
                <div>
                    <div>
                        {/* Copyright */}
                        <p className="text-[10px] opacity-70">&copy; Content by Mark G. Giraud, Website by Hakan Aytaylan</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;