import { React } from "react";
import { WMSTileLayer } from "react-leaflet";

const BasicWMSLayer = (props) => {
    const layerAttribution = "";

    return (
        <WMSTileLayer
            attribution={layerAttribution}
            url={props.url}
            layers={props.layers}
            format="image/png"
            transparent={true}
            maxZoom={props.maxZoom ?? 20}
            minZoom={props.minZoom ?? 0}
            opacity={props.opacity}>
        </WMSTileLayer>
    );
};

export default BasicWMSLayer;