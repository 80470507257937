import { React, useEffect, useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import MapComponent from "./pages/Map";
import About from "./pages/About";
import Layout from "./pages/Layout";
import Intro from "./component/components/Intro";

function App() {

    const [startIntro, SetStartIntro] = useState(false);
    useEffect(() => {
        let $link = document.createElement("link");
        document.head.appendChild($link);
        $link.rel = "stylesheet";
        $link.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
        $link.integrity = "sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ==";
        $link.crossorigin = "";
        
        setTimeout( () => SetStartIntro(true), 100);
    }, []);

    return (
        <>
        {startIntro === false ? null : <Intro/>}
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout/>}>
                    <Route index element={<MapComponent/>} />
                    <Route path="/about" element={<About/>}/>
                </Route>
            </Routes>
        </BrowserRouter>
        </>

    );
}

export default App;
