import React, { useState } from "react";
import { useMap } from "react-leaflet";
import * as config from "../../config";

const FastTravelButtons = (props) => {
    return (
        <div className="flex flex-col space-x-2 items-center">
            <div className="w-11/12 lg:w-1/2">
                <div className="flex justify-evenly">
                    <div><FastTravelButton flyLocationName={"Izmir"} flyToPos={config.izmirPosition} flyToZoom={config.initialZoomLevel} /></div>
                    <div><FastTravelButton flyLocationName={"Bornova"} flyToPos={config.bornovaPosition} flyToZoom={config.initialZoomLevel} /></div>
                </div>
            </div>
        </div>
    );
};

const FastTravelButton = (props) => {
    const map = useMap();
    const [expanded, setExpanded] = useState(false);

    function fastTravelTo(params) {
        map.flyTo(props.flyToPos, props.flyToZoom);
    }

    return (
        <div className="leaflet-control-container leaflet-bottom mb-6 sm:mb-0">
            <div className="relative right-[60px] leaflet-control-layers leaflet-control leaflet-bar">
                <a className={`location-button-${props.flyLocationName} cursor-pointer hover:bg-slate-100`}
                    onClick={fastTravelTo}
                    aria-label="Go to Location"
                    title="Go to Location"
                    style={{ height: "28px", width: "100px" }}
                >
                    <span className="opacity-80 text-sm">To {props.flyLocationName}</span>
                </a>
            </div>
        </div>
    );
};

export default FastTravelButtons;