import React, {useState, useEffect} from "react";
import "intro.js/introjs.css";
import { Steps } from "intro.js-react";


const Intro = () => {

    const [enabled,setEnabled] = useState(true);
    const [initialStep,setInitialStep] = useState(0);
   
    const onExit = () => {
        setEnabled(false);  
    };
    const steps = [
        {
            title: "Greetings",
            intro: "Welcome to Bournabat. <br/> Here is a short introduction..."
        },
        {
            element: ".leaflet-control-layers-toggle",
            intro: "Toggle layers and photos",
        },
        {
            element: ".opacity-control",
            intro: "Adjust layer opacity",
        },
        {
            element: ".my-location",
            intro: "Activate GPS <br/> (Requires Permission)",
        },
        {
            element: ".leaflet-control-zoom",
            intro: "Zoom in/out",
        }
    ];
    return (
        <Steps
            enabled={enabled}
            steps={steps}
            initialStep={initialStep}
            onExit={onExit}
            options={{ dontShowAgain: true,
                       dontShowAgainLabel: "Skip introduction next time",
                       dontShowAgainCookieDays: 7
                    }}
        />
    );
};

export default Intro;