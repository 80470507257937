import { React, useState } from "react";
import { LayerGroup, LayersControl, Pane } from "react-leaflet";
import PolygonDataLayer from "./PolygonDataLayer";
import Layers from "../../layerDefinition.json";
import BasicWMSLayer from "./BasicWMSLayer";
import * as config from "../../config";
import WFSDataLayer from "./WFSDataLayer";
import { StreetNamesLayer, StreetNumberLayer } from "./StreetInfo";
import PhotosLayer from "./Photos";
import VisibleWZoom from "./VisibleWZoom";
import MapOpacityControl from "../components/MapOpacityControl";

const ConfiguredLayers = (props) => {
    const AllLayers = Layers.Layers;
    const [opacity, setOpacity] = useState(0.35);

    return (
        <>
            {Object.keys(AllLayers).map((key) => <ConfiguredLayer key={key} name={key} layerConfig={AllLayers[key]} opacity={opacity} />)}
            <Pane name="labelPane" style={{ zIndex: 1500 }} />
            <Pane name="photoPane" style={{ zIndex: 1501 }} />
            <Pane name="infoPane" style={{ zIndex: 1502 }} />
            <MapOpacityControl setOpacity={setOpacity} />
        </>
    );
};

const ConfiguredLayer = (props) => {
    function generateLayer(name, key, location, layerConfig) {
        const url = config.qgis_server_url[`map_${location}`];
        switch (layerConfig.type) {
            case "PolygonData":
                return (
                    <WFSDataLayer key={key} name={key} url={url}>
                        <PolygonDataLayer textStyle={layerConfig.labelTextStyle}
                            style={layerConfig.style}
                            opacity={props.opacity}
                            labelVisibleZoomLevel={layerConfig.labelVisibleZoomLevel}
                            toolTip={layerConfig.toolTip} />
                    </WFSDataLayer>
                );
            case "BasicWMS":
                return (
                    <BasicWMSLayer key={key} layers={key} url={url} opacity={props.opacity} />
                );
            case "Other":
                {
                    const otherComponents = {
                        "Street_Names": StreetNamesLayer,
                        "Street_Numbers": StreetNumberLayer,
                        "Photographic_Angles": PhotosLayer
                    };
                    const OtherComponent = otherComponents[key];
                    return (
                        <WFSDataLayer key={key} name={key} url={url}>
                            <OtherComponent textStyle={layerConfig.labelTextStyle}
                                style={() => { return layerConfig.style; }}
                                labelVisibleZoomLevel={layerConfig.labelVisibleZoomLevel} />
                        </WFSDataLayer>
                    );
                }
            default:
                return;
        }
    }

    return (
        <VisibleWZoom visibleDistance={13}>
            <LayersControl.Overlay checked={props.layerConfig.defaultVisible} name={props.name}>
                <LayerGroup>
                    {Object.keys(props.layerConfig.sourceLayerNames).map((key) => generateLayer(props.name, key, props.layerConfig.location, props.layerConfig.sourceLayerNames[key]))}
                </LayerGroup>
            </LayersControl.Overlay>
        </VisibleWZoom>
    );
};

export default ConfiguredLayers;