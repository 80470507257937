import { React, useState } from "react";
import { useMapEvents } from "react-leaflet";
import { minZoomLevel } from "../../config";

const VisibleWZoom = (props) => {
    const map = useMapEvents({
        zoomend: () => {
            setZoomLevel(map.getZoom());
        }
    });
    const [zoomLevel, setZoomLevel] = useState(map.getZoom());

    if ( zoomLevel >= props.visibleDistance && zoomLevel > minZoomLevel) {
        return (
            <>
                {props.children}
            </>
        );
    }
    else {
        return null;
    }
};

export default VisibleWZoom;