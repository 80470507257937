import React, { useEffect, useState } from "react";

const WFSDataLayer = (props) => {
    const [LayerData, SetLayerData] = useState();

    useEffect(
        () => {
            fetch(`${props.url}/?SERVICE=WFS&VERSION=2.0.0&REQUEST=GetFeature&OUTPUTFORMAT=GeoJSON&TYPENAME=${props.name}`)
                .then(response => response.json())
                .then(data => SetLayerData(data));
        }, []);

    if (LayerData === undefined) {
        return null;
    }
    else {
        return (
            <>
                {React.cloneElement(props.children, { data: LayerData, ...props })}
            </>
        );
    }
};

export default WFSDataLayer;