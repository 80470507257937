import { React } from "react";
import { LayersControl, TileLayer } from "react-leaflet";
import Layers from "../../layerDefinition.json";
import * as config from "../../config";
import BasicWMSLayer from "./BasicWMSLayer";

const BaseLayers = (props) => {
    const AllLayers = Layers.BaseLayers;

    return (
        <>
            <LayersControl.BaseLayer checked={false} name="<b>None</b>">
                <TileLayer url="" maxZoom={config.maxZoomLevel} />
            </LayersControl.BaseLayer>
            {Object.keys(AllLayers).map((key) => <BaseLayer key={key} name={key} data={AllLayers[key]} url={config.qgis_server_url[`map_${AllLayers[key].location}`]} />)}
        </>
    );
};

const BaseLayer = (props) => {
    return (
        <LayersControl.BaseLayer checked={props.data.defaultVisible} name={props.name}>
            <BasicWMSLayer layers={props.data.sourceLayerNames}
                           url={props.url}
                           maxZoom={props.data.maxZoom}
                           minZoom={props.data.minZoom} />
        </LayersControl.BaseLayer >

    );
};

export default BaseLayers;