import React, { useEffect, useState } from "react";
import { Marker, Popup, Circle, useMap } from "react-leaflet";

const LocationControl = (props) => {
    const [locationState, setLocationState] = useState(false);

    function toggleLocationState() {
        setLocationState(!locationState);
    }

    return (
        <>
            <div className="leaflet-control-container">
                <div className="leaflet-bottom leaflet-right">
                    <div className="leaflet-control leaflet-bar" style={{"marginBottom":"112px"}}>
                        {locationState ?
                            <LocationEnabled toggleLocationState={toggleLocationState} /> :
                            <LocationDisabled toggleLocationState={toggleLocationState} />
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

const LocationEnabled = (props) => {
    const [position, setPosition] = useState(null);
    const [bbox, setBbox] = useState([]);
    const map = useMap();
    let isMounted = true;

    useEffect(() => {
        map.locate({ watch: true }).on({
            locationfound: function (e) {
                if (isMounted) {
                    setPosition({ center: e.latlng, radius: e.accuracy });
                    setBbox(e.bounds.toBBoxString().split(","));
                }
            },
            locationerror: function (e) {
            }
        });
        return () => {
            isMounted = false;
        };
    }, [map]);

    return (
        <>
            <a className="block w-[30px] h-[30px] bg-[url('../public/locicon.png')] bg-[length:24px_24px] bg-no-repeat bg-center cursor-pointer hover:bg-slate-100"
                onClick={props.toggleLocationState}
                aria-label="Toggle Location"
                title="Toggle Location" />

            {position === null ? null :
                <>
                    <Marker position={position.center}>
                        <Popup>
                            You are here. <br />
                        </Popup>
                    </Marker>
                    <Circle center={position.center} radius={position.radius} />
                </>
            }
        </>
    );
};

const LocationDisabled = (props) => {
    return (
        <a className="my-location block w-[30px] h-[30px] bg-[url('../public/locicon_off.png')] bg-[length:24px_24px] bg-no-repeat bg-center cursor-pointer hover:bg-slate-100"
            onClick={props.toggleLocationState}
            aria-label="Toggle Location"
            title="Toggle Location" />
    );
};

export default LocationControl;
