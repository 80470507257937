import React, {useState, useEffect} from "react";
import ReactMarkdown from "react-markdown";
import TestMarkdown from "./content/About/Test.md";

const About = () => {
    const [testContent, setTestContent] = useState("Dummy");

    useEffect(
        () => {
            fetch(TestMarkdown)
                .then(response => response.text())
                .then(text => setTestContent(text));
        }, []);
    

    return (
        <ReactMarkdown className="about-content">
            {testContent}
        </ReactMarkdown>
    );
};

export default About;