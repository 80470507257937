export const initialZoomLevel = 16;
export const maxNativeZoomLevel = 20;
export const maxZoomLevel = maxNativeZoomLevel;
export const minZoomLevel = 10;
export const bornovaPosition = [38.465839, 27.220768];
export const izmirPosition = [38.418889, 27.128697];
export const centerPosition = bornovaPosition;
export const qgis_server_url = {
    "map": process.env.REACT_APP_BORNOVA_MAP_SERVER_URL,
    "map_bornova": process.env.REACT_APP_BORNOVA_MAP_SERVER_URL,
    "map_izmir": process.env.REACT_APP_IZMIR_MAP_SERVER_URL,
    "img": process.env.REACT_APP_QGIS_IMG_SERVER_URL
};
