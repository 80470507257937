import { React, useMemo } from "react";
import { Marker, Tooltip } from "react-leaflet";
import * as ReactDOMServer from "react-dom/server";
import L from "leaflet";
import VisibleWZoom from "./VisibleWZoom";

const LabelData = (props) => {

    if (props.toolTip) {
        return (
            <VisibleWZoom visibleDistance={props.visibleDistance}>
                <TooltipContent data={props.data} textStyle={props.textStyle} />
            </VisibleWZoom>
        );
    } else {
        const myIcon = new L.DivIcon({
            html: ReactDOMServer.renderToStaticMarkup(<LabelDataContent textStyle={props.textStyle} data={props.data} />),
            className: "",
            iconSize: [0, 0]
        });

        return (
            <VisibleWZoom visibleDistance={props.visibleDistance}>
                <Marker data={props.data} position={props.center} icon={myIcon} interactive={false} zIndexOffset={0} pane="labelPane" />
            </VisibleWZoom>
        );
    }
};

const LabelDataContent = (props) => {

    const dataList = props.data;

    return (
        <div className="h-8 w-40 max-w-40 overflow-visible text-xs text-center -ml-20 -mt-3 list-none"
            style={props.textStyle}>
            {dataList}
        </div>
    );
};

const TooltipContent = (props) => {

    const dataList = props.data;

    return (
        <Tooltip pane="infoPane">
            <div className="whitespace-normal w-auto min-w-[220px] max-w-[40vw]" style={{ ...props.textStyle, marginLeft: "20px" }}>
                {dataList}
            </div>
        </Tooltip>
    );
};

export default LabelData;