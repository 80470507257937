import { React } from "react";
import { LayerGroup, GeoJSON } from "react-leaflet";
import "leaflet-textpath";
import VisibleWZoom from "./VisibleWZoom";
import LabelData from "./LabelData";

const StreetNamesLayer = (props) => {
    function featureContent(feature, layer) {
        var tooltipContent = `${feature.properties["Street ID"]}`;
        if ("null" !== tooltipContent)
            layer.setText(tooltipContent, { offset: 5, center: true, attributes: props.textStyle });
    }

    return (
        <VisibleWZoom visibleDistance={18}>
            <GeoJSON data={props.data}
                style={{ opacity: 0 }}
                onEachFeature={featureContent}
            />
        </VisibleWZoom>
    );
};

const StreetNumberLayer = (props) => {
    return (
        <LayerGroup>
            {
                props.data.features.map(layerData => {
                    const label = <LabelData key={layerData.id}
                        data={layerData.properties[Object.keys(layerData.properties)[0]]}
                        center={layerData.geometry.coordinates.slice().reverse()}
                        visibleDistance={props.labelVisibleZoomLevel}
                        textStyle={props.textStyle}
                    />;
                    return label;
                })
            }
        </LayerGroup>
    );

};

export { StreetNamesLayer, StreetNumberLayer };