import React from "react";
import { useMap } from "react-leaflet";

const MapOpacityControl = (props) => {
    const map = useMap();

    function valueChanged(e) {
        props.setOpacity(e.target.value / 100);
    }

    return (
        <div className="leaflet-control-container ">
            <div className="leaflet-top leaflet-right" style={{zIndex:799}}>
                <div className="flex h-full mt-8 justify-end ">
                    <div className="opacity-control leaflet-control leaflet-bar bg-slate-50 landscape:w-7/12 landscape:smh:w-full pl-2 pr-2 h-[34px] origin-bottom-right -rotate-90"
                        style={{"marginTop":"%50"}}
                        onTouchStart={() => map.dragging.disable()}
                        onTouchEnd={() => map.dragging.enable()}
                        onMouseEnter={() => map.dragging.disable()}
                        onMouseLeave={() => map.dragging.enable()}
                    >
                        <label hidden={true}>Map Opacity</label>
                        <input className="mt-[7px] w-full" type={"range"} min={0} max={100} step={5} onChange={valueChanged} title={"Slide to adjust opacity"} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MapOpacityControl;